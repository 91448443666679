<template>
    <section class="section">
        <ValidationObserver v-slot="{ handleSubmit }">
            <v-form class="mt-15" @submit.prevent="handleSubmit(onSubmit)">
                <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="90vh">
                    <v-container>
                        <v-tabs center-active>
                            <v-tab>{{ $t('CalendarDateProfile') }}</v-tab>
                            <v-tab>{{ $t('Trips') }}</v-tab>
                            <v-tab>{{ $t('Calendar') }}</v-tab>
                            <v-tab-item>
                                <v-row class="mt-10">
                                    <v-col cols="12" md="12">
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <ValidationProvider
                                                    :name="$t('Date')"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                                    <v-menu
                                                        ref="dateMenu"
                                                        v-model="dateMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="form.date"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                v-model="form.date"
                                                                :error="errors[0] ? true : false"
                                                                :disabled="isDisabled"
                                                                :label="$t('Date')"
                                                                readonly
                                                                filled
                                                                hide-details="auto"
                                                                shaped
                                                                v-bind="attrs"
                                                                v-on="on"></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="form.date" no-title scrollable>
                                                            <v-spacer></v-spacer>
                                                            <v-btn text color="primary" @click="dateMenu = false">
                                                                Cancel
                                                            </v-btn>
                                                            <v-btn
                                                                text
                                                                color="primary"
                                                                @click="$refs.dateMenu.save(form.date)">
                                                                OK
                                                            </v-btn>
                                                        </v-date-picker>
                                                    </v-menu>

                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>

                                            <v-col cols="12" md="4">
                                                <ValidationProvider
                                                    :name="$tc('ExceptionType')"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                                    <v-select
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        :items="exceptionTypes"
                                                        filled
                                                        shaped
                                                        item-value="value"
                                                        item-text="name"
                                                        hide-details
                                                        v-model="form.exception_type"
                                                        :label="$tc('ExceptionType')"></v-select>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <div class="d-flex mt-8">
                                    <v-text-field
                                        expanded
                                        v-model="searchTripsQuery"
                                        @change="searchTrips()"
                                        :placeholder="$t('Search')"
                                        outlined
                                        hide-details
                                        dense
                                        :prepend-inner-icon="mdiMagnify"
                                        @keydown.enter.prevent="searchTrips"
                                        class="user-search me-3 mb-4">
                                    </v-text-field>
                                </div>
                                <ValidationProvider
                                    :name="$t('Trips')"
                                    rules="required"
                                    v-slot="{ errors }"
                                    ref="trips">
                                    <v-data-table
                                        show-select
                                        calculate-widths
                                        :items-per-page="5"
                                        :footer-props="{
                                            'items-per-page-options': [5, 10, 15, 20],
                                            'items-per-page-text': $t('RowsPerPage'),
                                        }"
                                        :header-props="{ 'sort-by-text': $t('SortBy') }"
                                        :headers="tripsTableColumns"
                                        :items="form.trips.length > 0 ? orderBySelectedTrip : tripListTable"
                                        :server-items-length="totalTripListTable"
                                        v-model="form.trips"
                                        name="trips"
                                        required
                                        :options.sync="tripOptions"
                                        @update:page="tripPaginationHandle"
                                        height="450"
                                        fixed-header>
                                    </v-data-table>
                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </v-tab-item>
                            <v-tab-item>
                                <v-container class="mt-4">
                                    <div id="routes-list" class="mt-8">
                                        <div class="d-flex">
                                            <!-- search -->
                                            <v-text-field
                                                expanded
                                                :placeholder="$t('Search')"
                                                outlined
                                                hide-details
                                                dense
                                                :prepend-inner-icon="mdiMagnify"
                                                class="user-search me-3 mb-4"
                                                @change="searchCalendars()"
                                                v-model="searchCalendarsQuery">
                                            </v-text-field>
                                        </div>

                                        <v-data-table
                                            show-select
                                            calculate-widths
                                            single-select
                                            :items-per-page="5"
                                            :footer-props="{
                                                'items-per-page-options': [5, 10, 15, 20],
                                                'items-per-page-text': $t('RowsPerPage'),
                                            }"
                                            :header-props="{ 'sort-by-text': $t('SortBy') }"
                                            :headers="calendarTableColumns"
                                            :items="
                                                form.calendar.length > 0 ? orderBySelectedCalendar : calendarListTable
                                            "
                                            :server-items-length="totalCalendarListTable"
                                            :options.sync="calendarOptions"
                                            @update:page="calendarPaginationHandle"
                                            :loading="isLoading"
                                            v-model="form.calendar"
                                            height="450"
                                            fixed-header>
                                        </v-data-table>
                                    </div>
                                </v-container>
                            </v-tab-item>
                        </v-tabs>
                    </v-container>
                </v-sheet>
                <v-app-bar height="80" absolute color="white" elevate-on-scroll scroll-target="#scrolling-techniques-7">
                    <v-btn @click="goBack" class="square-button mx-3" color="grey-light">
                        <v-icon large color="grey">
                            {{ mdiChevronLeft }}
                        </v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="!addCalendarDate">
                        <p class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                            {{ $t('CalendarDateProfile') }}
                        </p>
                        <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                            {{ $t('CalendarDateProfile') }}
                        </p>
                    </v-toolbar-title>
                    <v-toolbar-title v-else>
                        <p class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                            {{ $t('AddCalendarDate') }}
                        </p>
                        <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                            {{ $t('AddCalendarDate') }}
                        </p>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <div v-if="!addCalendarDate">
                        <v-btn type="submit" v-show="!isDisabled" :loading="isLoading" class="mx-3" color="primary">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                                {{ mdiCheck }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="ehite">
                                {{ mdiCheck }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Save') }}</span>
                        </v-btn>
                        <v-btn v-show="!isDisabled" @click="isDisabled = true" outlined class="mx-3" color="error">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="error">
                                {{ mdiClose }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="error">
                                {{ mdiClose }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Cancel') }}</span>
                        </v-btn>
                        <v-btn v-show="isDisabled" @click="editInformation" class="mx-3" color="grey-light">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="grey">
                                {{ mdiFileDocumentEditOutline }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="grey">
                                {{ mdiFileDocumentEditOutline }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Edit') }}</span>
                        </v-btn>

                        <v-dialog transition="dialog-top-transition" max-width="600">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-show="isDisabled" class="mx-3" color="grey-light" v-bind="attrs" v-on="on">
                                    <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="grey">
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                    <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="grey">
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                    <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Delete') }}</span>
                                </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                                <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/delete.png"></v-img>
                                <v-card>
                                    <v-card-text class="text-center">
                                        <p class="title font-weight-semibold black--text mt-12">
                                            {{ $t('Alerts.Sure') }}
                                        </p>
                                    </v-card-text>
                                    <v-card-text class="text-center">
                                        <p class="body-2">
                                            {{ $t('Alerts.SureDeleteCalendarDate') }}
                                            <strong class="black--text"></strong> ?<br />
                                            {{ $t('Alerts.Irreversible') }}
                                        </p>
                                    </v-card-text>

                                    <v-card-actions class="justify-end mt-4">
                                        <v-btn
                                            @click="deleteCalendarDate"
                                            :loading="isLoading"
                                            color="error"
                                            class="px-5"
                                            >{{ $t('Buttons.Delete') }}</v-btn
                                        >
                                        <v-btn text @click="dialog.value = false" :loading="isLoading">{{
                                            $t('Buttons.Cancel')
                                        }}</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </div>
                    <v-btn v-else type="submit" :loading="isLoading" class="mx-3" color="primary">
                        <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Conclude') }}</span>
                    </v-btn>
                </v-app-bar>
            </v-form>
        </ValidationObserver>
        <v-snackbar :timeout="2000" top v-model="createSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.AddCalendarDateSuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="updateSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.UpdateCalendarDateSuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="deleteSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.DeleteCalendarDateSuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="isError" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/failed.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Error') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.ErrorMessage') }}</p>
        </v-snackbar>
    </section>
</template>

<script>
    import {
        mdiChevronLeft,
        mdiFileDocumentEditOutline,
        mdiInformationOutline,
        mdiPlus,
        mdiTrashCanOutline,
        mdiCheck,
        mdiClose,
        mdiChevronRight,
        mdiMagnify,
    } from '@mdi/js';
    import _ from 'lodash';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import useTripsList from '@/views/route/trips-list/useTripsList';
    import useCalendarList from '@/views/route/calendar/useCalendarList';

    const initialState = () => ({
        isLoading: false,
        form: {
            calendar: [],
            exception_type: '',
            trips: [],
            date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        },

        isLoadingTable: true,
    });

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
        },

        data() {
            return {
                ...initialState(),
                isDisabled: true,
                valid: false,

                addCalendarDate: false,

                isError: false,
                createSuccess: false,
                updateSuccess: false,
                deleteSuccess: false,
                dateMenu: false,
                tripQuery: {},
                calendarQuery: {},
                searchTripsQuery: null,
                searchCalendarsQuery: null,
                selectedCalendars: [],
                tripOptions: {
                    page: 1,
                },
                calendarOptions: {
                    page: 1,
                },
                exceptionTypes: [
                    { name: 'calendarDatesAvailable', value: 'O1' },
                    { name: 'calendarDatesNotAvailable', value: 'O2' },
                ],
            };
        },
        created() {
            this.tripQuery = this.$store.getters['app-routes/getTripsQuery'];
            this.fetchTripList();
            this.fetchCalendarList();
            for (let index = 0; index < this.exceptionTypes.length; index++) {
                this.exceptionTypes[index].name = this.$t(this.exceptionTypes[index].name);
            }
            if (this.$route.params.id != 'add') {
                this.addCalendarDate = false;
                this.isDisabled = true;
                this.getCalendarDate();
            } else {
                this.addCalendarDate = true;
                this.isDisabled = false;
            }
        },

        methods: {
            editInformation() {
                this.isDisabled = !this.isDisabled;
            },

            getCalendarDate() {
                this.isLoading = true;
                this.$store
                    .dispatch('app-routes/fetchCalendarDate', this.$route.params.id)
                    .then((response) => {
                        this.isLoading = false;

                        this.form = response.data.data;
                        this.form.calendar = [response.data.data.calendar];
                    })
                    .catch((error) => {
                        if (error.response.status === 404) {
                            this.form = {};
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },

            async onSubmit() {
                if (this.addCalendarDate) {
                    await this.onAddCalendarDate();
                } else await this.onUpdateCalendarDate();

                setTimeout(() => {
                    this.$router.push({ name: 'RoutesList', params: { tab: 1, tripTab: 2, calendarTab: 1 } });
                }, 1500);
            },

            async onAddCalendarDate() {
                this.isLoading = true;

                this.form.trips = this.form.trips.map((item) => {
                    return item.id;
                });
                this.form.calendar = this.form.calendar.map((item) => {
                    return item.id;
                });

                await this.$store
                    .dispatch('app-routes/addCalendarDate', this.form)
                    .then(async (response) => {
                        this.createSuccess = true;
                    })
                    .catch((error) => {
                        this.isError = true;
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.isDisabled = true;
                    });
            },

            async onUpdateCalendarDate() {
                this.isLoading = true;
                this.form.trips = this.form.trips.map((item) => {
                    return item.id;
                });
                this.form.calendar = this.form.calendar.map((item) => {
                    return item.id;
                });
                await this.$store
                    .dispatch('app-routes/updateCalendarDate', this.form)
                    .then(async (response) => {
                        this.updateSuccess = true;
                    })
                    .catch((error) => {
                        this.isError = true;
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.isDisabled = true;
                    });
            },

            deleteCalendarDate() {
                this.isLoading = true;
                this.$store
                    .dispatch('app-routes/deleteCalendarDate', this.$route.params.id)
                    .then((response) => {
                        this.deleteSuccess = true;
                        setTimeout(() => {
                            this.$router.push({ name: 'RoutesList', params: { tab: 1, tripTab: 2, calendarTab: 1 } });
                        }, 1500);
                    })
                    .catch((error) => {
                        this.isError = true;
                    });
            },

            searchTrips() {
                this.isLoading = true;
                this.$store.commit('app-routes/setTripSearch', this.searchTripsQuery);
                this.tripQuery = this.$store.getters['app-routes/getTripsQuery'];
                this.tripQuery.pagination.page = 1;
                this.fetchTripList();
                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },

            searchCalendars() {
                this.isLoading = true;
                this.$store.commit('app-routes/setCalendarSearch', this.searchCalendarsQuery);
                this.calendarQuery = this.$store.getters['app-routes/getCalendarsQuery'];
                this.calendarQuery.pagination.page = 1;
                this.fetchCalendarList();
                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },

            tripPaginationHandle(e) {
                if (this.tripOptions.page > this.tripQuery.pagination.page) {
                    this.tripQuery.pagination.page = this.tripOptions.page;
                    this.$store.commit('app-routes/setRoutesQuery', this.tripQuery);
                    this.fetchTripList();
                }
            },

            calendarPaginationHandle(e) {
                if (this.calendarOptions.page > this.calendarQuery.pagination.page) {
                    this.calendarQuery.pagination.page = this.calendarOptions.page;
                    this.$store.commit('app-routes/setRoutesQuery', this.calendarQuery);
                    this.fetchCalendarList();
                }
            },

            goBack() {
                this.$router.push({ name: 'RoutesList', params: { tab: 1, tripTab: 2, calendarTab: 1 } });
            },
        },

        computed: {
            orderBySelectedTrip() {
                for (let index = 0; index < this.form.trips.length; index++) {
                    this.tripListTable.unshift(this.form.trips[index]);

                    //remove duplicates from array
                    this.tripListTable = this.tripListTable.filter(
                        (trip, index, self) => index === self.findIndex((i) => i.id === trip.id),
                    );
                }

                return this.tripListTable;
            },
            orderBySelectedCalendar() {
                for (let index = 0; index < this.form.calendar.length; index++) {
                    this.calendarListTable.unshift(this.form.calendar[index]);

                    //remove element from array if null
                    this.calendarListTable = this.calendarListTable.filter((item) => item !== null);

                    //remove duplicates from array
                    this.calendarListTable = this.calendarListTable.filter(
                        (calendar, index, self) => index === self.findIndex((i) => i.id === calendar.id),
                    );
                }

                return this.calendarListTable;
            },
        },

        setup() {
            const { tripsTableColumns, tripListTable, totalTripListTable, fetchTripList } = useTripsList();
            const { calendarListTable, calendarTableColumns, totalCalendarListTable, fetchCalendarList } =
                useCalendarList();
            return {
                mdiInformationOutline,
                mdiFileDocumentEditOutline,
                mdiCheck,
                mdiClose,
                mdiTrashCanOutline,
                mdiPlus,
                mdiChevronLeft,
                mdiChevronRight,
                mdiMagnify,

                tripsTableColumns,
                tripListTable,
                totalTripListTable,
                fetchTripList,

                calendarListTable,
                calendarTableColumns,
                totalCalendarListTable,
                fetchCalendarList,
            };
        },
    };
</script>

<style lang="scss" scoped>
    .v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
        box-shadow: 0 0px 0px 0px rgb(94 86 105 / 42%) !important;
    }

    .route-color-picker {
        position: absolute;
        z-index: 10;
        display: none;
    }

    .route-text-color-picker {
        position: absolute;
        z-index: 10;
        display: none;
    }
</style>
